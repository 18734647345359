<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="wrap">
        <div class="search-container">
            <el-row>
                <el-col :span="20">
                    <el-row>
                        <el-form ref="form" :model="form" label-width="100px">
                            <el-col :span="12">
                                <el-form-item label="开卡时间：">
                                    <el-date-picker
                                        v-model="form.time"
                                        type="daterange"
                                        :clearable="true"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </el-col>
                <el-col :span="4" class="btn">
                    <el-button  class="search-button" @click="searchEvent">{{$t("search")}}</el-button>
                    <el-button  class="clean-button"  @click="cleanForm">{{$t("clean")}}</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="table-container">
            <el-table
                :data="tableData"
                :height="tableHeight - 4"
                ref="tableData"
                style="width: 100%"
                border
                :row-class-name="tableRowClassName"
            >
                <el-table-column
					prop="name"
                    min-width="200"
                    align="center"
					label="会员名称">
				</el-table-column>
                <el-table-column
					prop="cardName"
                    min-width="200"
                    align="center"
					label="会员卡">
				</el-table-column>
                <el-table-column
					prop="cardType"
                    min-width="150"
                    align="center"
					label="会员卡类型">
				</el-table-column>
                <el-table-column
					prop="cardState"
                    min-width="150"
                    align="center"
					label="会员卡状态">
				</el-table-column>
                <el-table-column
					prop="activeTime"
                    min-width="150"
                    align="center"
					label="开卡时间">
				</el-table-column>
                <el-table-column
					prop="useTicket"
                    min-width="150"
                    align="center"
					label="使用次数">
				</el-table-column>
                <el-table-column
					prop="expireTime"
                    min-width="150"
                    align="center"
					label="截止时间">
                    <template slot-scope="scope">
                        <span>{{scope.row.expireTime || '-'}}</span>
                    </template>
				</el-table-column>
                <el-table-column
					prop="surplusTicket"
                    min-width="150"
                    align="center"
					label="剩余次数">
                    <template slot-scope="scope">
                        <span>{{scope.row.surplusTicket || '-'}}</span>
                    </template>
				</el-table-column>
            </el-table>
        </div>
        <div class="page-warp" >
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="form.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="form.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="form.total"
            >
            </el-pagination>
            <el-button class="default-button" @click="exportMemberCard" v-if="buttonAuth.includes('venueOperation:usageStatistics:export')">导出数据</el-button>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import filwDownload from "@/mixins/download"

export default {
    mixins: [mixin, filwDownload],
    data() {
        return {
            form: {
                time: '',
                endDate: '',
                beginDate: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
        };
    },

    methods: {
        searchData() {
            if (this.form.time && this.form.time.length > 0) {
                this.form.beginDate = this.form.time[0]
                this.form.endDate = this.form.time[1]
            } else {
                this.form.beginDate = ''
                this.form.endDate = ''
            }
            this.$http.post(apis.cardUseStatistics, this.form).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.form.total = res.data.total
                    this.pageNum = res.data.pageNum
                }
            })
        },
        cleanForm() {
            this.form = {
                time: '',
                endDate: '',
                beginDate: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            }
            this.searchEvent();
        },
        exportMemberCard() {
            this.$http.post(apis.exportCardUseRecord, {
                beginDate: this.form.beginDate,
                endDate: this.form.endDate,
            }).then((res) => {
                console.log(res)
                this.exportFun(res.data.rows.url, res.data.rows.fileName)
            })
        },
    },
    updated() {
        this.$nextTick(() => {
            this.$refs.tableData.doLayout()
        })
    },
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-warp{
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between
}
.btn{
    display: flex;
    justify-content: flex-end
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
